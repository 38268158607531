import React from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Span } from "../components/Core";
let key = 'general';
const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;
const Faq = (props) => {
  console.log(" url ", props.location.search);
  if(props.location.search === '?content=location'){
    key = 'location';
  }
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="9">
                <Title variant="hero">Frequently Asked Question</Title>
                {/* <Text>
                  Create custom landing pages with Omega that converts
                </Text> */}
              </Col>
            </Row>
          </Container>
        </Section>
        <Section>
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey={key}>
              <Row>
                <Col md="4" className="mb-5 mb-md-0">
                  <NavStyled className="flex-column mr-md-5" activeKey={key}  onSelect={(k) => key = k}>
                    <Nav.Link eventKey="general">General</Nav.Link>
                    {/* <Nav.Link eventKey="accounts">Accounts</Nav.Link> */}
                    <Nav.Link eventKey="sales">Sales</Nav.Link>
                    <Nav.Link eventKey="support">Support</Nav.Link>
                    <Nav.Link eventKey="location">Location</Nav.Link>
                    {/* <Nav.Link eventKey="license">License</Nav.Link> */}
                  </NavStyled>
                </Col>
                <Col md="8">
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Can anybody add their business listing?
                          </Title>
                          <Text variant="small">
                            Yes.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What do you charge for your listings?
                          </Title>
                          <Text variant="small">
                            Currently we do not charge for having your business listed. We will be introducing premium services in the coming weeks.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            The business information listed is incorrect?
                          </Title>
                          <Text variant="small">
                            At the bottom of every business detail page there’s Suggest an edit option.
                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Why is my business not listed?
                          </Title>
                          <Text variant="small">
                            Our data team is working hard to assemble the data on a daily basis. You can list your business right from the Kesto App by pressing the “blue” add button. Our team will review your data for correctness and publish it on Kesto. It's that easy! {" "}
                            <a href="/get-listed">
                              <Span color="primary">Click here</Span>
                            </a>

                          </Text>
                        </Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            What features are you working on?
                          </Title>
                          <Text variant="small">
                            Quite a few actually. You can sign up on our email list to be notified of new releases.
                          </Text>
                        </Box>
                        {/* <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Are classified ads free?
                          </Title>
                          <Text variant="small">
                            For to post now but in the coming weeks we will start charging reasonable fees to cover our infrastructure costs.
                          </Text>
                        </Box> */}
                      </Box>
                    </Tab.Pane>
                    
                    <Tab.Pane eventKey="sales">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            Do you sell advertising?
                          </Title>
                          <Text variant="small">
                            Eventually yes. We're currently focused on adding more data into our system and also finalizing our advertising platform which will be released in a few weeks.
                            We will run simple advertisements for the first 5 customers for "FREE" for a limited number of days in each region on a first-come first-served basis. Reach out to us.
                          </Text>
                        </Box>
                        
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="support">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How can I reach you if I’ve any questions?
                          </Title>
                          <Text variant="small">
                            Please use 
                            {" "}
                            <a href="mailto:support+kesto@jotika.net">
                              <Span color="primary">Contact us</Span>
                            </a>
                            {" "}
                            to reach out to us. We will respond to each and every inquiry, that’s a promise.
                          </Text>
                        </Box>
                        
                      </Box>
                    </Tab.Pane>
                    <Tab.Pane eventKey="location">
                      <Box>
                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            How do I enable Location Services on my mobile device?
                          </Title>
                          <Text variant="small">
                            Many aspects of the Kesto app, such as ETC Card Scan, seeing "Nearby" feeds, and local searches are not optimized unless you allow Kesto to use your device's location. With location access enabled on your device, the Kesto app will default to your current location when it's
                            opened and a search is entered. You can change this default location instead by selecting a city nearest to you. You can also adjust your settings to allow Kesto
                            to receive your location while the app runs in the background. This allows you to receive
                            updates about nearby businesses and events via push notification, and better personalizes
                            your local experience on Kesto.
                          </Text>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            To turn on location access on an iPhone or iPad
                          </Title>
                          <Text variant="small" ml={3}>
                            1. Tap on your device's Settings app
                          </Text>
                          <Text variant="small" ml={3}>
                            2. Tap Privacy
                          </Text>
                          <Text variant="small" ml={3}>
                            3. Tap Location Services
                          </Text>
                          <Text variant="small" ml={3}>
                            4. Toggle the switch to On
                          </Text>
                          <Text variant="small" ml={3}>
                            5. Scroll down to the Kesto app icon
                          </Text>
                          <Text variant="small" ml={3}>
                            6. Select While Using the App or Always
                          </Text>

                          <Text variant="small" mt={3}>
                            Alternatively you can change these settings from inside the iPhone or iPad app
                          </Text>
                          <Text variant="small" ml={3}>
                            1. Tap More
                          </Text>
                          <Text variant="small" ml={3}>
                            2. Tap Settings
                          </Text>
                          <Text variant="small" ml={3}>
                            3. Tap Location Services
                          </Text>
                          <Text variant="small" ml={3}>
                            4. Tap Location Settings which will take you to your device's main settings
                          </Text>
                          <Text variant="small" ml={3}>
                            5. Tap Location
                          </Text>
                          <Text variant="small" ml={3}>
                            6. Select While Using the App or Always
                          </Text>
                        </Box>

                        <Box mb={4}>
                          <Title variant="card" mb={3} fontSize="24px">
                            To turn on location access on an Android device
                          </Title>
                          <Text variant="small" ml={3}>
                            1. Tap on your device's Settings app
                          </Text>
                          <Text variant="small" ml={3}>2. Tap Location</Text>
                          <Text variant="small" ml={3}>3. Tap Google Location Reporting</Text>
                          <Text variant="small" ml={3}>4. Tap Location Reporting</Text>
                          <Text variant="small" ml={3}>5. Toggle the switch to On</Text>

                          <Text variant="small" mt={3}> Alternatively, you can change these settings from inside the Android Kesto app</Text>
                          <Text variant="small" ml={3}>1. Tap More</Text>
                          <Text variant="small" ml={3}>2. Tap Settings</Text>
                          <Text variant="small" ml={3}>3. Tap Location Access</Text>
                          <Text variant="small" ml={3}>4. Clicking on your current setting will take you to the App info page</Text>
                          <Text variant="small" ml={3}>5. Click on Permissions, then Location</Text>
                          <Text variant="small" ml={3}>6. Select Allow all the time or Allow only while using the app</Text>

                          <Text variant="small" mt={3}>
                            You can still search for businesses without turning on location access - just make sure that
                            "Nearby" functionality will not work so you will have to pick a city nearest to you manually.
                          </Text>
                        </Box>
                        
                      </Box>
                    </Tab.Pane>
                    
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default Faq;
